<template>
  <link href="https://gulagu.net/storage/css/map.css" rel="stylesheet">
  <link href="https://gulagu.net/storage/css/app.css" rel="preload" as="style">
  <link href="https://gulagu.net/storage/css/app.css" rel="stylesheet">
  <div id="map_container">
    <span class="city-label" style="top: 165px; left: 105px;">Санкт-Петербург</span>
    <span class="city-label" style="top: 214px; left: 105px;">Москва</span>
    <svg>
      <path v-for="path in paths" :key="path.id" @click="activate_path(path)"
        :class="{ active: active_path == path, city: path.outline.label.x == 0 }"
        style="stroke-opacity: 0.5; cursor: default;" fill="#7798ba" stroke="#ffffff" :d="path.outline.path"
        stroke-width="1.01" stroke-opacity="0.5"></path>
    </svg>
  </div>
  <div class="layout row">
    <div class="growing_col col-lg-3 m-0 p-0">
      <div class="tag">МЫ РАСТЕМ</div>
      <div class="participant">
        <h1>{{ participants }}</h1>
        <p>участника</p>
      </div>
      <div class="posts">
        <h1>{{ posts }}</h1>
        <p>постов и открытых писем</p>
      </div>
      <div class="videos">
        <h1>{{ videos }}</h1>
        <p>видео</p>
      </div>
      <div class="comments">
        <h1>{{ comments }}</h1>
        <p>комментариев</p>
      </div>
      <div class="smi_publish">
        <h1>{{ smi_publish }}</h1>
        <p>публикаций в СМИ</p>
      </div>
    </div>
    <!-- <div class="live_col col-lg-5 m-0 p-0">
      <div class="tag">ПРЯМОЙ ЭФИР</div>
      <div class="last_comment layout-cell layout-hide">
        <TransitionGroup name="list" tag="ul">
          <li v-for="comment in comments_list" :key="comment.id">
            <div class="comment_block">
              <strong><a :href="'/user/' + comment.user_id">{{ comment.name }}</a> написал комментарий</strong>
              <a :href="comment.post.url">{{ comment.short }} ...</a>
            </div>
          </li>
        </TransitionGroup>
      </div>
    </div> -->
    <div class="map_col col-lg-9 m-0 p-0">
      <div class="tag">ИНФОРМАЦИЯ</div>
      <div class="last_comment layout-cell layout-scrollbar">
        <TransitionGroup name="list" tag="ul">
          <li v-for="(post, i) in posts_list" :key="i">
            <div class="comment_block">
              <strong><a :href="'/user/' + post.user_id">{{ post.user.name + ' ' + post.user.lastname }}</a> написал
                пост</strong>
              <a :href="post.url">{{ post.title.ru }} ...</a>
            </div>
          </li>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script>
import paths from '@/inc/paths.js';

export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      participants: 5164 + 16000,
      posts: 1560 + 8000,
      videos: '1,6 тыс',
      comments: 4183 + 125000,
      smi_publish: 204,
      paths,
      comments_list: [],
      posts_list: [],
      active_path: null,
      offset: 5
    }
  },
  mounted() {
    // this.live()
    // setInterval(this.live, 10_000)

    // this.axios.get('https://gulagu.net/api/lastcomments').then(response => {
    //   this.comments_list = response.data
    // })

    this.axios.get('https://gulagu.net/api/info').then(response => {
      this.posts_list = response.data
    })
  },
  methods: {
    activate_path(path) {
      if (this.active_path != path) this.active_path = path
      else this.active_path = null
    },
    // live() {
    //   this.axios.get('https://gulagu.net/api/mapinfo').then(response => {
    //     this.participants = response.data.participants
    //     this.posts = response.data.posts
    //     this.videos = response.data.videos
    //     this.comments = response.data.comments
    //     this.smi_publish = response.data.smi_publish
    //   })

    //   this.axios.get('https://gulagu.net/api/lastcomment/' + this.offset).then(response => {
    //     this.comments_list.pop()
    //     this.comments_list.unshift(response.data)

    //     this.offset += 1
    //   })
    // }
  },
  watch: {
    active_path() {
      if (this.active_path != null) {
        this.axios.get('https://gulagu.net/api/info/' + this.active_path.id).then(response => {
          this.posts_list = response.data
        })
      } else {
        this.axios.get('https://gulagu.net/api/info').then(response => {
          this.posts_list = response.data
        })
      }
    }
  }
}
</script>

<style>
svg {
  width: 100%;
  min-height: 360px;
}

path:not(.active):hover {
  fill: #5b7591 !important;
}

path.active {
  fill: #ff3a3a;
}

path.city {
  stroke: yellow !important;
  fill: yellow !important;
  stroke-opacity: 1 !important;
  stroke-width: 4 !important;
}

.city-label {
  position: absolute;
  display: block;
  background: rgba(255, 255, 255, .6);
  padding: 0 4px;
  border-radius: 8px;
  z-index: 0;
}

.city-label:hover {
  display: none;
}

.comment_block a {
  word-wrap: anywhere;
}

/* .list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
} */

.layout-scrollbar {
  max-height: 500px;
  overflow: scroll;
}

.layout-hide {
  max-height: 500px;
  overflow: hidden !important;
}

@media (max-width: 768px) {
  #map_container {
    display: none !important;
  }
}
</style>
